const isAbleToDownloadZipReport = [
	"14467598-7197-424c-e043-08db976c0599", // PROD G4F
	"89b5f1e1-4c27-4a98-d963-08dc2d88d275", // PROD CEQ LOCADORA LTDA
	"21e173bf-2a05-41a7-1d66-08dbf5093065", // DEV G4F
	"e3f8bb9c-8d42-4fa2-12a4-08da2dcc7dc9",
	"914713d7-65cf-4dab-4522-08dbf50a2747",
	"8a1dbec5-852d-424e-6935-08dcd41c59b7",
	"e0d58412-5679-4e47-b5bd-08dccb765abf"
	// "9b452fd1-bb3c-4b7f-b15e-08d962616dfd" // DEV Senior Sistemas
];

export const verifyIsAbleToDownloadZipReport = (id: string) => {
	const findItem = isAbleToDownloadZipReport.find(i => i === id.toLowerCase());
	return Boolean(findItem);
};
